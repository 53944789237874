import footerImg from './footer_img.png';
import './App.css';
import { useState } from 'react';
import es from './es.json';
import en from './en.json';

function App() {
  const [formStatus, setFormStatus] = useState(false);
  // detectar si el navegador está en español o inglés
  const lang = navigator.language.split('-')[0];
  const langData = lang === 'es' ? es : en;

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const body = JSON.stringify({
      "email": document.getElementById('email').value,
      "fname": e.target.name.value,
      "lname": e.target.lastname.value,
    });
    // fetch
    fetch('https://rest.bhavani.app/admin/newsletters/subscribe', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: body
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .then(() => setFormStatus(true))
    .catch(error => alert("Error..."))
  }

  const formContainer = () => {
    if (formStatus === false) {
      return (
        <form method="post" className="validate" onSubmit={handleSubmit}>
          <label>
            <p>{langData.newsletter.inputName}</p>
          <input type="text" name="name" id="name" className="name" required />
          </label>
          <label>
            <p>{langData.newsletter.inputLastname}</p>
            <input type="text" name="lastname" id="lastname" className="lastname" required />
          </label>
          <label>
            <p>{langData.newsletter.inputEmail}</p>
            <input type="email" name="email" id="email" className="email" required />
          </label>
          <input type="submit" value={langData.newsletter.button} name="subscribe" id="mc-embedded-subscribe" className="button" />
        </form>
      )
    } {
      return (
        <div className="formSuccess">
          <h2>{langData.newsletter.thanksTitle}</h2>
          <p>{langData.newsletter.thanksText}</p>
        </div>
      )
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://my.bhavani.app/assets/images/bhavani_black.png" className="App-logo" alt="logo" />
        <h1>
          {langData.title}
        </h1>
        <p>{langData.subtitle}</p>
      </header>
      <section className="newsletterForm">
        {formContainer()}
      </section>
    </div>
    );
}

export default App;
